import React, { ReactElement } from "react";
import MainBanner from "@components/homewarrantycenter/mainBanner/mainBanner";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import HomeWarrantyCenterWrapper from "@components/layouts/homewarrantycenterWrapper";
import NewJerseyDisclaimer from "@components/shared/newJerseyDisclaimer";
const OffersList = dynamic(() => import("@components/shared/offersList"));
const HomeWarrantyBasics = dynamic(
    () => import("@components/homewarrantycenter/homeWarrantyBasics"),
);
const HomeWarrantyBenefits = dynamic(
    () => import("@components/homewarrantycenter/homeWarrantyBenefits"),
);
const HomeownersInsurance = dynamic(
    () => import("@components/homewarrantycenter/homeownersInsurance"),
);
const HomeWarrantyRight = dynamic(
    () => import("@components/homewarrantycenter/homeWarrantyRight"),
);

export default function HomeWarrantyCenterHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <HomeWarrantyCenterWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <HomeWarrantyBasics />
            <HomeWarrantyBenefits />
            <HomeownersInsurance />
            <HomeWarrantyRight />
            <NewJerseyDisclaimer backGround="#f7cdbe" />
        </HomeWarrantyCenterWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
